import React from "react";
import Layout from "../components/layout";
import ListPart from "../components/list-part";
import Seo from "../components/seo";
import { navigate } from "gatsby";

export default function Detail({ pageContext }) {
  const manga = pageContext.manga;
  const epiList = manga.epiList;
  return (
    <Layout>
      <Seo
        title={manga.name}
        description={`『${manga.name}』、の掲載媒体を表示しております。最大無料掲載話：${manga.free_episode_count}`}
      />
      <section className="d_manga_wrapper">
        <h1 className="detail_h1">{manga.name}</h1>
        <span className="detail_author">{manga.author}</span>
        <span className="detail_latest">{manga.latest}</span>
        <div className="d_manga_detail_wrapper">
          <img className="d_imgImg" src={manga.imageUrl} alt={manga.name} />
          {manga.description}
        </div>
      </section>
      <section className="d_m_wrapper">
        {epiList.map((epiObj, index) => {
          const mediaId = Number(epiObj.mediaId);
          const episodes = epiObj.episodes;
          const Names = {
            1: "少年ジャンププラス",
            2: "マガポケ",
            3: "コミックDAYS",
          };
          return (
            <div key={index}>
              <h2>{Names[mediaId]}</h2>
              <div className="osusumeParent detailList">
                {episodes.map((epi, index2) => {
                  return (
                    <ListPart
                      key={index2}
                      to={epi.link}
                      isNewTab={true}
                      imgStyle={null}
                      imgSrc={epi.thumbnail}
                      name={epi.title}
                      desc=""
                      up={epi.date}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </section>
      <section className="d_m_wrapper">
        <div className="buttonDiv">
          <button className="btn as-normal" onClick={() => navigate(-1)}>
            戻る
          </button>
        </div>
      </section>
    </Layout>
  );
}
